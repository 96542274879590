var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('iq-card',{staticClass:"bg-transparent shadow-none w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between pl-2 pr-3"},[_c('ais-instant-search',{attrs:{"index-name":"instant_search","search-client":_vm.searchClient}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"p-0 d-none d-lg-block",attrs:{"md":"3"}},[_c('EcommerceFilter',{staticClass:"mt-0",attrs:{"responsive":false}})],1),_c('b-col',{staticClass:"p-0",attrs:{"md":"12","lg":"9"}},[_c('b-card-body',{staticClass:"pt-0 bg-transparent"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',[_c('div',{staticClass:"d-flex align-items-center justify-content-between p-2"},[_c('div',{staticClass:"d-flex iq-algolia-search"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"d-lg-none mr-2 font-weight-bold",attrs:{"variant":" iq-bg-primary"}},[_c('i',{staticClass:"ri-menu-line mr-0 p-0"})]),_c('ais-search-box',{attrs:{"id":"searchbox","show-loading-indicator":""}}),_c('ais-powered-by',{staticClass:"d-flex align-content-center",attrs:{"id":"powered-by"}})],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"iq-algolia-sort",attrs:{"id":"sort-by"}},[_c('ais-sort-by',{attrs:{"class-names":{
                                  'ais-SortBy': 'iq-algolia-sort',
                                  'ais-SortBy-select': ''
                                },"items":[
                                  {
                                    value: 'instant_search',
                                    label: 'Featured'
                                  },
                                  {
                                    value: 'instant_search_price_asc',
                                    label: 'Price asc.'
                                  },
                                  {
                                    value: 'instant_search_price_desc',
                                    label: 'Price desc.'
                                  }
                                ]}})],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":`${
                                    _vm.listType === 'grid'
                                      ? 'primary'
                                      : ' iq-bg-primary'
                                  }`},on:{"click":function($event){_vm.listType = 'grid'}}},[_c('i',{staticClass:"ri-grid-fill mr-0 p-0"})]),_c('b-button',{attrs:{"variant":`${
                                    _vm.listType === 'list'
                                      ? 'primary'
                                      : ' iq-bg-primary'
                                  }`},on:{"click":function($event){_vm.listType = 'list'}}},[_c('i',{staticClass:"ri-list-unordered mr-0 p-0"})])],1)])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{class:`iq-product-layout-${_vm.listType}`},[_c('ais-hits',{attrs:{"class-names":{
                            'ais-Hits': 'iq-product',
                            'ais-Hits-list': 'iq-product-list',
                            'ais-Hits-item': 'iq-product-item iq-card'
                          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(_vm.listType === 'grid')?_c('div',{staticClass:"text-center"},[_c('ProductGrid',{attrs:{"item":item,"selectedCart":_vm.checkCart(item),"selectedWishCart":_vm.wishCart(item)},on:{"cart":_vm.addToCart,"wishlist":_vm.addToWishlist}})],1):_c('b-card-body',[_c('ProductList',{attrs:{"item":item,"selectedCart":_vm.checkCart(item),"selectedWishCart":_vm.wishCart(item)},on:{"cart":_vm.addToCart,"wishlist":_vm.addToWishlist}})],1)]}}])})],1),_c('NoResults')],1)],1)],1),_c('ais-pagination',{staticClass:"mt-2 justify-content-center",attrs:{"class-names":{
                    'ais-Pagination': 'pagination',
                    'ais-Pagination-list': 'pagination',
                    'ais-Pagination-item': 'page-item',
                    'ais-Pagination-link': 'page-link',
                    'ais-Pagination-item--selected': 'active'
                    // ...
                  }}})],1)],1)],1),_c('b-modal',{staticClass:"p-0",attrs:{"id":"modal-1","title":"Filter","size":"xl","modal-class":"ecommerce p-0","hide-header":"","hide-footer":""},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('b-row',{staticClass:"fixed-bottom p-2 bg-white"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"primary iq-waves-effect","size":"lg","block":""},on:{"click":function($event){return hide()}}},[_vm._v("Apply")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ResetFilter')],1)],1),_c('EcommerceFilter',{staticClass:"mt-2",attrs:{"shadow":false,"responsive":true}})]}}])})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }